import React, { useState, useEffect } from 'react';
//import { NavLink } from 'react-router-dom';
import './App.css';

import Header from './components/Header/Header';
import Hero from './components/UI/Hero';
import Counter from './components/UI/Counter';
import Services from './components/UI/Services';
import Packages from './components/UI/Packages';
import About from './components/UI/About';
import Team from './components/UI/Team';
import Blog from './components/UI/Blog';
// import Testimonial from './components/UI/Testimonial';
import Newsletter from './components/UI/Newsletter';
import Footer from './components/Footer/Footer';
import Form from './components/UI/Form';

function App() {
  const [theme, setTheme] = useState('');

  const toggleTheme = () => {
    setTheme(theme === '' ? 'light-theme' : '');
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <div>
      <Header theme={theme} toggleTheme={toggleTheme} />
      {/* NavLinks */}
      {/* <nav>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/">Hero</NavLink>
        <NavLink to="/counter">Counter</NavLink>
        <NavLink to="/services">Services</NavLink>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/team">Team</NavLink>
        <NavLink to="/blog">Blog</NavLink>
        <NavLink to="/form">Form</NavLink>
        <NavLink to="/testimonial">Testimonial</NavLink>
        <NavLink to="/newsletter">Newsletter</NavLink>
        <NavLink to="/footer">Footer</NavLink>
      </nav> */}

      {/* Routes */}
      <main>
        <Hero theme={theme} />
        <Counter />
        <Services />
        <About />
        <Team />
        <Blog />
        <Form />
        {/* <Testimonial /> */}
        <Newsletter />
        <Footer />
      </main>
    </div>
  );
}

export default App;
